import React, { useEffect } from "react";
import AOS from "aos";
import { NextSeo } from "next-seo";

import elements, {
  NoContentFound,
  RenderDraggableElement,
  RenderHtmlElement,
  useSite,
} from "@wazoe/elements";

import getStaticPageProps from "../utils/getStaticProps";
import getStaticPagePaths from "../utils/getStaticPaths";

export const getStaticPaths = getStaticPagePaths;
export const getStaticProps = getStaticPageProps;

export default function Page({
  page,
  header,
  footer,
  pageData,
  site,
  textPlaceholders,
  locale,
}) {
  const { setLocale } = useSite();
  const contentArray = [...header, ...pageData.content, ...footer];

  const elementComponent = (type, content) => {
    if (type === "html") return RenderHtmlElement;
    if (type === "draggable") return RenderDraggableElement;

    return elements[content.type];
  };

  useEffect(() => {
    AOS.init({
      useClassNames: true,
      initClassName: false,
      animatedClassName: "animate__animated",
      startEvent: "load",
      once: false,
    });
    setLocale(locale);
  }, [locale, setLocale]);

  return (
    <>
      <NextSeo
        title={`${
          page.type === "repeatableItem"
            ? textPlaceholders["%NAME%"]
            : pageData.name
        } | ${site.name}`}
        description={pageData.seo?.seoDescription}
        additionalMetaTags={[
          {
            ...(pageData.seo?.seoKeywords && {
              property: "keywords",
              content: pageData.seo?.seoKeywords,
            }),
          },
        ]}
      />

      {contentArray?.length === 0 && <NoContentFound />}
      {contentArray.map((content) => {
        const ElementComponent = elementComponent(content.elementType, content);

        return (
          <ElementComponent
            key={content.id}
            textPlaceholders={textPlaceholders}
            {...{
              elementProps: content.props,
              ...{ forms: site.forms },
              ...(content.type.includes("repeatable") && {
                repeatables: site.repeatables,
              }),
              ...(content.type.includes("navbar") && {
                menus: site.menus,
                pages: site.pages,
                activePage:
                  pageData?.path === "/"
                    ? `/${locale?.toLowerCase()}`
                    : `/${locale?.toLowerCase()}${pageData?.path}`,
              }),
            }}
          />
        );
      })}
    </>
  );
}
